import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { API_ROUTES } from '../config';
import { FaSpinner } from "react-icons/fa";


const GaleriaNoticias = () => {
  const [noticias, setNoticias] = useState([]);
  const [showModal, setShowModal] = useState(false); // Estado del modal
  const [modalData, setModalData] = useState({}); // Datos para el contenido del modal
  const [loading, setLoading] = useState(true); // Estado de carga


  useEffect(() => {
    getNoticias();
    document.querySelector('#noticias').scrollIntoView({ behavior: 'smooth' });
  }, []);
  

  // Función para dar vuelta la fecha al formato "DD-MM-AAAA"
  const darVueltaFecha = (fecha) => {
    const [año, mes, dia] = fecha.split('-');
    return `${dia}-${mes}-${año}`;
  };

  // Proceso para mostrar todas las noticias
  const getNoticias = async () => {
    try {
      const res = await axios.get(API_ROUTES.noticias);
      // Ordenar las noticias por createdAt de la más nueva a la más antigua
      const sortedNoticias = res.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    
        setNoticias(sortedNoticias);
        setLoading(false);
    
    } catch (error) {
      console.error('Error al obtener noticias:', error);
      setLoading(false); 
    }
  };

  const openModal = (noticia) => {
    setModalData(noticia);
    setShowModal(true);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };




  return (
    <section id='noticias' className="d-flex flex-column justify-content-center align-items-center contenedor-noticias">
      <h1 className='titulo'>NOTICIAS</h1>
      <div className="container">
        <div id='noticias' className="row ContenidoNoticias">
        {loading ? ( // Mostrar "Cargando..." si el estado de carga es verdadero
            <div className="col-12 text-center"> {/* Utiliza col-12 para que ocupe todo el ancho */}
            <p>Cargando <FaSpinner className='iconoBuscando' /></p>
          </div>
          ) :
          Array.isArray(noticias) && noticias.length > 0 ? (
            noticias.slice(0, 6).map((noticia, index) => (
              <div key={index} className="col-md-4 tarjeta">
                <div className="cardNoticias">
                  <div className="card-body" style={{ marginBottom: '50px' }}>
                    <p className="card-text" style={{ marginBottom: '0', fontSize: '14px' }}>
                      <span style={{ fontWeight: 'bold' }}>{noticia.estado.toUpperCase()}</span> - <span>{darVueltaFecha(noticia.createdAt)}</span>
                    </p>
                    <h5 className="card-title tituloNoticias" style={{ height: '50px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                     <p>{truncateText(noticia.titulo, 35)}</p>
                    </h5>
                    <img
                      src={`${API_ROUTES.noticiasUploads}${noticia.noticia_img}`}
                      alt="Imagen de la card"
                      className="card-img-top"
                      style={{ width: '100%', height: '300px', objectFit: 'cover' }}
                    />
                    <button className="btn btn-secondary" style={{ marginTop: '10px', display: 'block', marginLeft: 'auto' }} onClick={() => openModal(noticia)}>Más Información</button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No se encontrarón noticias.</p>
          )}
        </div>
      </div>

      {/* Modal */}
      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>

          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Modal.Title>{modalData.titulo}</Modal.Title>
          </div>

        </Modal.Header>
        <Modal.Body>
          {modalData.estado && (
            <div><span style={{ fontWeight: 'bold' }}>{modalData.estado.toUpperCase()}</span></div>
          )}
          {modalData.createdAt && (
            <div><span>Fecha: {darVueltaFecha(modalData.createdAt)}</span></div>
          )}

          <div style={{ textAlign: 'center' }}>
            <img
              src={`${API_ROUTES.noticiasUploads}${modalData.noticia_img}`}
              alt={`Imagen de ${modalData.titulo}`}
              className="img-fluid imgModalNoticias"
              style={{ display: 'inline-block', borderRadius: '10px', marginBottom: '50px', marginTop: '30px', width: '500px' }}
            />
          </div>
          <p style={{ whiteSpace: 'pre-line', textAlign: 'justify' }}>{modalData.detalle}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-right mt-3">
        <Link
          to={`/noticias`}
          className="btn btn-secondary"
        >
          Ver más
        </Link>
      </div>
    </section>
  );
};

export default GaleriaNoticias;
