import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, ListGroup, Form, Button, Card, Modal } from 'react-bootstrap';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ubi from '../img/ubi.png';
import L from 'leaflet';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaHome, FaMap, FaSpinner } from "react-icons/fa";
import libra from '../img/libra.png';
import otro from '../img/otro.png';
import sin_sistema from '../img/sin_sistema.png'
import sin_avatar from '../img/sin-avatar.png';
import { API_ROUTES } from '../config';
import Select from "react-select";
import { Helmet } from 'react-helmet';



const Receptores = () => {
  const [receptores, setReceptores] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedReceptor, setSelectedReceptor] = useState(null);
  const [loadingCoordinates, setLoadingCoordinates] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(''); // Nuevo estado
  const [regiones, setRegiones] = useState([]);
  const [filteredReceptores, setFilteredReceptores] = useState([]); // Nuevo estado para los receptores filtrados
  const [comunas, setComunas] = useState([]); // Estado para almacenar las comunas de la región seleccionada
  const [selectedComunas, setSelectedComunas] = useState([]);
  const [selectedComunaIds, setSelectedComunaIds] = useState([]);
  const [selectedTipoSistema, setSelectedTipoSistema] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mostrarTodasLasComunas, setMostrarTodasLasComunas] = useState(false);




  useEffect(() => {
    getReceptores();
    getRegiones(); // Agregar esta llamada
    getComunas();
  }, []);



  const getReceptores = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(API_ROUTES.receptoresConComunas);
      setReceptores(res.data);
      setFilteredReceptores(res.data);
    } catch (error) {
      console.error('Error al obtener receptores:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getRegiones = async () => {
    try {
      const res = await axios.get(API_ROUTES.receptoresRegiones); // Cambia la URL según tu API
      setRegiones(res.data);
    } catch (error) {
      console.error('Error al obtener regiones:', error);
    }
  };

  const getComunas = async () => {
    try {
      const res = await axios.get(API_ROUTES.receptoresComunas); // Cambia la URL según tu API
      setComunas(res.data);
    } catch (error) {
      console.error('Error al obtener comunas:', error);
    }
  };

  const customIcon = L.icon({
    iconUrl: ubi,
    iconSize: [25, 25],
    iconAnchor: [12, 25],
    popupAnchor: [0, -25],
  });



  const handleRegionChange = (regionId) => {
    setSelectedRegion(regionId);
    //console.log(regionId)

    // Filtrar las comunas basadas en si el ID de la región seleccionada coincide con la propiedad id_Region de cada comuna
    const filteredComunas = comunas.filter(comuna => comuna.id_region === parseInt(regionId));
    setSelectedComunas(filteredComunas.map(comuna => comuna.id)); // Guardar los ids de las comunas filtradas
    //console.log("comunas:", filteredComunas);
  };

  const handleComunaChange = (comunaId) => {
    setSelectedComunaIds(prevSelectedComunaIds => {
      if (prevSelectedComunaIds.includes(comunaId)) {
        // Si ya está seleccionada, removerla del arreglo
        return prevSelectedComunaIds.filter(id => id !== comunaId);
      } else {
        // Si no está seleccionada, agregarla al arreglo
        return [...prevSelectedComunaIds, comunaId];
      }
    });
  };

  const toggleMostrarTodasLasComunas = () => {
    setMostrarTodasLasComunas(!mostrarTodasLasComunas);
  };


  const handleTipoSistemaChange = tipo => {
    setSelectedTipoSistema(selectedTipo => (selectedTipo === tipo ? null : tipo));

  };

  const handleApplyFilters = () => {
    applyFilters();
  };

  const applyFilters = useCallback(() => {
    if (!selectedRegion && selectedComunaIds.length === 0 && !selectedTipoSistema) {
      setFilteredReceptores(receptores);
      return;
    }

    let tempFilteredReceptores = receptores;

    if (selectedRegion) {
      tempFilteredReceptores = tempFilteredReceptores.filter(
        receptor => receptor.regione.id === parseInt(selectedRegion)
      );
    }

    if (selectedComunaIds.length > 0) {
      tempFilteredReceptores = tempFilteredReceptores.filter(receptor =>
        selectedComunaIds.some(comunaId =>
          receptor.comunas.map(comuna => comuna.id).includes(comunaId)
        )
      );
    }



    if (selectedTipoSistema === "CON") {
      tempFilteredReceptores = tempFilteredReceptores.filter(
        receptor => receptor.sistema === "LIBRA" || receptor.sistema === "OTRO"
      );
    } else if (selectedTipoSistema === "SIN") {
      tempFilteredReceptores = tempFilteredReceptores.filter(
        receptor => receptor.sistema === "SIN SISTEMA"
      );
    }

    setFilteredReceptores(tempFilteredReceptores);

  }, [selectedRegion, selectedComunaIds, selectedTipoSistema, receptores]);

  const handleClearFilters = () => {
    setSelectedRegion('');
    setSelectedComunaIds([]);
    setSelectedTipoSistema(null);
    setFilteredReceptores(receptores); // Restablecer los receptores filtrados al arreglo original
  };



  const openModal = async (receptor) => {
    setShowModal(true);
    setLoadingCoordinates(true); // Iniciar el estado de carga de coordenadas

    try {
      const direccionConRegion = `${receptor.direccion}, ${receptor.regione.nombre}, Chile`;
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${direccionConRegion}&format=json&limit=1`);
      if (response.data && response.data.length > 0) {
        const coordinates = response.data[0];
        receptor.coordinates = {
          latitude: parseFloat(coordinates.lat),
          longitude: parseFloat(coordinates.lon)
        };
        setSelectedReceptor(receptor);
      } else {
        // No se encontraron datos de geolocalización, puedes mostrar un mensaje o tomar otra acción
        setSelectedReceptor({ ...receptor, coordinates: null }); // Establecer coordinates como null para mostrar el mensaje
      }
    } catch (error) {
      console.error('Error al obtener coordenadas:', error);
    } finally {
      setLoadingCoordinates(false); // Finalizar el estado de carga de coordenadas
    }
  };
  const closeModal = () => {
    setShowModal(false);
    setSelectedReceptor(null);
  };

  const regionOptions = [
    { value: '', label: 'Todas las regiones' },
    ...regiones.map(region => ({
      value: region.id,
      label: region.nombre
    }))
  ];

  const options = comunas
    .filter(comuna => comuna.id_region === parseInt(selectedRegion))
    .map(comuna => ({
      value: comuna.id,
      label: comuna.nombre
    }));

  // Convertir IDs de comunas seleccionadas a objetos con las propiedades value y label
  const selectedComunaOptions = selectedComunaIds.map(id => {
    const comuna = comunas.find(comuna => comuna.id === id);
    return {
      value: comuna.id,
      label: comuna.nombre
    };



  });

  return (

    <div className='contenido'>
      <Helmet>
        <title>Receptores - Asociación Nacional de Receptores Judiciales de Chile.</title>
      </Helmet>
      <Row >
        <Col md={4} lg={4} xl={2} style={{ marginTop: '25px' }}>
          <h5>Filtros</h5>
          <ListGroup>
            <ListGroup.Item>
              <h6>Seleccionar Región:</h6>
              <Select
                options={regionOptions}
                value={regionOptions.find(option => option.value === selectedRegion)}
                onChange={selectedOption => {
                  handleRegionChange(selectedOption.value);
                }}
              />
            </ListGroup.Item>
            {selectedRegion && (
              <React.Fragment>
                <ListGroup.Item>
                  <h6>Seleccionar Comunas:</h6>
                  <Select
                    isMulti
                    options={options}
                    value={selectedComunaOptions}
                    onChange={selectedOptions => {
                      setSelectedComunaIds(selectedOptions.map(option => option.value));
                    }}
                    placeholder="Selecciona una comuna"
                  />
                </ListGroup.Item>
              </React.Fragment>
            )}
            {
              <ListGroup.Item>
                <h6>Sistema Informático de Gestión:</h6>
                <Form.Check
                  type="checkbox"
                  label="Con Sistema"
                  checked={selectedTipoSistema === "CON"}
                  onChange={() => handleTipoSistemaChange("CON")}
                />
                <Form.Check
                  type="checkbox"
                  label="Sin Sistema"
                  checked={selectedTipoSistema === "SIN"}
                  onChange={() => handleTipoSistemaChange("SIN")}
                />
              </ListGroup.Item>}
            <ListGroup.Item>
              <Button variant="secondary" className="btnFiltros w-100 text-center" onClick={handleApplyFilters}>
                Aplicar Filtros
              </Button>
            </ListGroup.Item>
            <ListGroup.Item>
              <Button variant="secondary" className="btnFiltros w-100 text-center" onClick={handleClearFilters}>
                Limpiar Filtros
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <h1 className="titulo-receptores">RECEPTORES</h1>
          {isLoading && <p>Buscando...</p>}
          {!isLoading && filteredReceptores.length === 0 && <p>No se encontraron receptores.</p>}
          {!isLoading && filteredReceptores.length > 0 && (


            <Row >
              {filteredReceptores
                .sort((a, b) => {
                  const receptorA = a.nombre.toLowerCase();
                  const receptorB = b.nombre.toLowerCase();
                  return receptorA.localeCompare(receptorB); // Utilizando localeCompare para comparación sensible a localización
                })
                .map(receptor => (
                  <Col key={receptor.id} xs={6} sm={4} md={4} lg={4} xl={2} className="mb-4 card-column">
                    <Card className="cardReceptores">
                      <div className="square-image-container">
                        {receptor.receptor_img ? (
                          <div
                            className="square-image"
                            style={{
                              backgroundImage: `url(${API_ROUTES.receptoresUploads}${receptor.receptor_img})`,
                            }}
                          ></div>
                        ) : (

                          <div
                            className="square-image"
                            style={{
                              backgroundImage: `url(${sin_avatar})`,
                            }}
                          ></div>

                        )}
                      </div>
                      <Card.Body>
                        <Card.Header>
                          <Card.Title style={{ justifyContent: 'center', height: '50px', display: 'flex', alignItems: 'center', fontSize: receptor.nombre.length > 30 ? '16px' : '18px' }}>{receptor.nombre}</Card.Title>
                        </Card.Header>
                        <ListGroup variant="flush">
                          <ListGroup.Item className='tituloJurisdiccion d-none d-sm-block'>Jurisdicción:</ListGroup.Item>
                          <ListGroup.Item className="custom-list-item" style={{ justifyContent: 'center', height: '100px', display: 'flex', alignItems: 'center' }}>{receptor.regione ? receptor.regione.nombre : 'Sin región'}</ListGroup.Item>
                          <ListGroup.Item>Sistema Informatico de Gestión:</ListGroup.Item>
                          <ListGroup.Item style={{ height: '60px', marginTop: '15px' }}> {receptor.sistema}</ListGroup.Item>
                          <Button variant="secondary" style={{ marginTop: '10px' }} onClick={() => openModal(receptor)}>Detalle</Button>
                        </ListGroup>

                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
          )}
        </Col>
      </Row>




      <Modal show={showModal}
        onHide={closeModal}
        size="xl"
        dialogClassName="custom-modal"


      >
        <Modal.Body style={{ maxHeight: '90vh', overflowY: 'auto' }}>
          <div className="container"> {/* Contenedor para aplicar márgenes */}
            <Row className="justify-content-center modal-receptores"> {/* Centra el contenido horizontalmente */}
              <Col xs={12} sm={12} md={12} lg={6} style={{ borderRadius: '10px' }} className="d-flex flex-column justify-content-center align-items-center">
                {selectedReceptor && (
                  <>
                    {selectedReceptor.receptor_img ? (
                      <img
                        src={`${API_ROUTES.receptoresUploads}${selectedReceptor.receptor_img}`}
                        alt={`Imagen de ${selectedReceptor.nombre}`}
                        className="imgModal"

                      />
                    ) : (

                      <img
                        src={sin_avatar}
                        alt={`Imagen de ${selectedReceptor.nombre}`}
                        className="imgModal"

                      />

                    )}
                    <h5 style={{ width: '225px', textAlign: 'center', marginTop: '10px' }}>{selectedReceptor.nombre}</h5>

                  </>
                )}
              </Col>

              <Col xs={12} sm={12} md={12} lg={6} className="d-flex flex-column justify-content-center align-items-center">
                {selectedReceptor && (
                  <div>
                    <h5 className='tituloDatos'>DATOS</h5>
                  </div>
                )}
                {loadingCoordinates ? (
                  <div className="d-flex justify-content-center align-items-center text-center cargadorModal">
                    <div className="mx-auto">
                      <FaSpinner className='iconoBuscando' />
                      <p className="mb-0">Buscando...</p>
                    </div>
                  </div>

                ) : (
                  selectedReceptor && selectedReceptor.coordinates ? (

                    <MapContainer
                      className='mapa'
                      center={[selectedReceptor.coordinates.latitude, selectedReceptor.coordinates.longitude]}
                      zoom={15}

                    >


                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      <Marker
                        position={[selectedReceptor.coordinates.latitude, selectedReceptor.coordinates.longitude]}
                        icon={customIcon}
                      >
                        <Popup>
                          <img src={ubi} alt="ubicacion" width="45" height="50" />
                          <p>{selectedReceptor.direccion}</p>
                        </Popup>
                      </Marker>
                      <hr className="linea-horizontal" />
                    </MapContainer>
                  ) : (

                    <p>No se encontró dirección en el mapa</p>
                  )
                )}

                {/* Datos adicionales debajo del mapa */}

                {selectedReceptor && (
                  <div style={{ maxWidth: '100%' }}>
                    <ul className='listado-datos' style={{ marginTop: '0', listStyleType: 'none', padding: 0 }}>
                      <li ><FaMapMarkerAlt size={20} /> <span style={{ fontWeight: 600 }}>Direccion: </span>{selectedReceptor.direccion}, Of {selectedReceptor.oficina}</li>
                      <li><FaPhoneAlt size={20} /> <span style={{ fontWeight: 600 }}>Teléfono:</span> {selectedReceptor.telefono}</li>
                      <li>
                        <FaEnvelope size={20} />
                        <span style={{ fontWeight: 600 }}> Correo:</span>
                        <a style={{textDecoration:'none', color:'inherit'}} href={`mailto:${selectedReceptor.correo}`}> {selectedReceptor.correo}</a>
                      </li>
                      <li><FaHome size={20} /><span style={{ fontWeight: 600 }}> Jurisdicción:</span>  {selectedReceptor.regione.nombre}</li>
                      <li>
                        <FaMap /> <span style={{ fontWeight: 600 }}>Comunas:</span>{' '}
                        {selectedReceptor.comunas
                          ? mostrarTodasLasComunas
                            ? selectedReceptor.comunas.map(comuna => comuna.nombre).join(', ')
                            : selectedReceptor.comunas.slice(0, 5).map(comuna => comuna.nombre).join(', ') +
                            (selectedReceptor.comunas.length > 10 ? '...' : '')
                          : 'N/A'}
                      </li>
                      {selectedReceptor.comunas && selectedReceptor.comunas.length > 5 && (
                        <Button variant="secondary" style={{ border: '0px', fontSize: '10px' }} onClick={toggleMostrarTodasLasComunas}>
                          {mostrarTodasLasComunas ? 'Ver menos' : 'Ver más'}
                        </Button>
                      )}
                    </ul>
                    <div style={{ width: '225px' }}>
                      <h6>Sistema Informatico de Gestión:</h6>
                      <div className='contenidoTiposistema'>
                        {selectedReceptor.sistema === 'LIBRA' && (
                          <div className="mx-auto">
                            <img src={libra} alt="Libra" className='tipoSistema' />
                          </div>
                        )}
                        {selectedReceptor.sistema === 'OTRO' && (
                          <div className="mx-auto">
                            <img src={otro} alt="Otro" className='tipoSistema' />
                          </div>
                        )}
                        {selectedReceptor.sistema === 'SIN SISTEMA' && (
                          <div className="mx-auto">
                            <img src={sin_sistema} alt="Sin sistema" className='tipoSistema' />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Receptores;