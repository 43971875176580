import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import { API_ROUTES } from '../config';
import { FaSpinner } from "react-icons/fa";



const Banner = () => {
  const [carrusels, setCarrusels] = useState([]);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    getCarrusels();
  }, []);

  const getCarrusels = async () => {
    try {
      const res = await axios.get(API_ROUTES.carrusels);
      setCarrusels(res.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener carrusels:', error);
      setLoading(false);
    }
  };

  return (
    <section className='seccionCarrusel'>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '700px' }}>

          <p>Cargando<FaSpinner className='iconoBuscando' /></p>
        </div>
      ) : (
        <Carousel id="carrusel" className='carousel-container'>
          {carrusels.map((carrusel, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={`${API_ROUTES.carruselsUploads}${carrusel.img}`}
                style={{ height: '700px' }}
                alt={`Carrusel ${index + 1}`}
                loading="lazy"
              />
              <Carousel.Caption className='carrusel'>
                <h2>{carrusel.titulo ? carrusel.titulo : ''}</h2>
                <p>{carrusel.subtitulo ? carrusel.subtitulo : ''}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </section>
  );
};

export default Banner;