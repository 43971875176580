import React from 'react'
import logo from '../img/logo.png';

const Footer = () => {
    return (

        <div className='footer'>
            <div className='footer-text'>
                Desarrollado por <a href='https://www.smartinfo.cl' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Smartinfo LTDA.</a>
            </div>
            <div className='footer-logo'><img src={logo} alt="Logo Asociacion" style={{ height: '40px' }} /></div>
            <div className='footer-social-icons'>
                <a style={{textDecoration:'none', color:'inherit'}} href="mailto:contacto@areceptoresjudicialeschile.cl">contacto@areceptoresjudicialeschile.cl</a>
            </div>
        </div>

    )
}

export default Footer