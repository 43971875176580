import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import libra from '../../img/libra.png';
import otro from '../../img/otro.png';
import sin_sistema from '../../img/sin_sistema.png';
import { useParams } from 'react-router-dom';
import { API_ROUTES } from '../../config';
import { FaSave } from 'react-icons/fa';
import { useAuth } from '../../AuthContext';
import { useForm } from 'react-hook-form';



const CompEditReceptor = () => {
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    const { AlertaExitosaEditar } = useAuth();
    const { receptorId } = useParams();
    const navigate = useNavigate();

    const nombre = watch('nombre');
    const correo = watch('correo');
    const direccion = watch('direccion');
    const oficina = watch('oficina');
    const telefono = watch('telefono');
    //const [nombre, setNombre] = useState('');
    //const [correo, setCorreo] = useState('');
    //const [direccion, setDireccion] = useState('');
    //const [oficina, setOficina] = useState('');
    //const [telefono, setTelefono] = useState('');
    const [regionId, setRegionId] = useState('');
    const [sistema, setSistema] = useState('');
    const [receptorImg, setReceptorImg] = useState(null);
    const [regiones, setRegiones] = useState([]);
    const [regionComunas, setRegionComunas] = useState([]);
    const [comunasSeleccionadas, setComunasSeleccionadas] = useState([]);
    const [selectAllComunas, setSelectAllComunas] = useState(false);

    useEffect(() => {
        fetchReceptorDetails();
        fetchRegiones();
    }, [receptorId]);

    const fetchReceptorDetails = async () => {
        try {
            const response = await axios.get(`${API_ROUTES.receptores}${receptorId}`);
            const receptorData = response.data;

            setValue('nombre', receptorData.nombre);
            setValue('correo', receptorData.correo);
            setValue('direccion', receptorData.direccion);
            setValue('oficina', receptorData.oficina);
            setValue('telefono', receptorData.telefono);

            //setNombre(receptorData.nombre);
            //setCorreo(receptorData.correo);
            //setDireccion(receptorData.direccion);
            //setOficina(receptorData.oficina);
            //setTelefono(receptorData.telefono);
            if (receptorData.id_region) {
                setRegionId(receptorData.id_region);
                fetchComunasByRegion(receptorData.id_region);
            }
            setRegionId(receptorData.id_region);
            setSistema(receptorData.sistema);
            setReceptorImg(receptorData.receptorImg);
            fetchComunasByRegion(receptorData.id_region);

            if (Array.isArray(receptorData.comunas)) {
                const comunaIds = receptorData.comunas.map(comuna => comuna.id);
                setComunasSeleccionadas(comunaIds);
            } else {
                setComunasSeleccionadas([]);
            }

            //console.log('Receptor data:', receptorData);
        } catch (error) {
            console.error('Error al obtener detalles del receptor:', error);
        }
    };

    const fetchComunasByRegion = async (regionId) => {
        try {
            const response = await axios.get(`${API_ROUTES.receptoresRegiones}${regionId}`);
            setRegionComunas(response.data.comunas);
        } catch (error) {
            console.error('Error al obtener comunas:', error);
        }
    };

    const fetchRegiones = async () => {
        try {
            const response = await axios.get(API_ROUTES.receptoresRegiones);
            setRegiones(response.data);
        } catch (error) {
            console.error('Error al obtener regiones:', error);
        }
    };

    const handleImagenChange = (event) => {
        setReceptorImg(event.target.files[0]);
    };

    const handleRegionChange = (event) => {
        const selectedRegionId = event.target.value;
        setRegionId(selectedRegionId);
        setComunasSeleccionadas([]); // Esto desasociará las comunas anteriores
        fetchComunasByRegion(selectedRegionId);
    };

    const handleSistemaChange = (event) => {
        setSistema(event.target.value);
    };

    const handleComunaChange = (event) => {
        const comunaId = parseInt(event.target.value, 10);

        if (event.target.name === 'selectAllComunas') {
            const selected = event.target.checked;
            setSelectAllComunas(selected);
            if (selected) {
                const comunasIds = regionComunas.map((comuna) => comuna.id);
                setComunasSeleccionadas(comunasIds);
            } else {
                setComunasSeleccionadas([]);
            }
        } else {
            if (!isNaN(comunaId)) {
                if (comunasSeleccionadas.includes(comunaId)) {
                    const updatedComunas = comunasSeleccionadas.filter(id => id !== comunaId);
                    setComunasSeleccionadas(updatedComunas);
                } else {
                    setComunasSeleccionadas([...comunasSeleccionadas, comunaId]);
                }
            }
        }

        //console.log(comunasSeleccionadas);
    };

    const enviar = async (event) => {
        //event.preventDefault();
        AlertaExitosaEditar();

        //console.log("receptor_img:", receptorImg);
        const formData = new FormData();
        formData.append('nombre', nombre);
        formData.append('correo', correo);
        formData.append('direccion', direccion);
        formData.append('oficina', oficina);
        formData.append('telefono', telefono);
        formData.append('id_region', regionId);
        formData.append('sistema', sistema);
        formData.append('receptor_img', receptorImg);
        formData.append('comunas', comunasSeleccionadas.join(','));

        //console.log("Contenido del FormData:", formData);

        try {
            //console.log('Sending request with data:', formData);
            await axios.put(`${API_ROUTES.receptores}${receptorId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            //console.log('Receptor editado correctamente');
            navigate('/adminreceptores');
        } catch (error) {
            console.error('Error al actualizar el receptor:', error);
        }
    };

    return (
        <div className="container" style={{ marginTop: '80px', marginBottom: '80px' }} >
            <h3>Editar receptor</h3>
            <form onSubmit={handleSubmit(enviar)} encType="multipart/form-data">
                <div className="row">
                    <div className="col-md-6 col-lg-12 mb-3">
                        <div className="form-group">
                            <label className="form-label">Nombre</label>
                            <input
                                // value={nombre}
                                {...register("nombre", { required: true, minLength: 3, maxLength: 100 })}
                                //onChange={(e) => setNombre(e.target.value)}
                                type="text" className="form-control"
                                placeholder='Ingrese un nombre, Ej: Juan Perez Perez'
                            />
                            {errors.nombre && <p className='Msjvalidaciones'>Ingrese un nombre (entre 3 y 100 caracteres).</p>}
                        </div>
                        <div className="form-group">
                            <label className="form-label">Correo</label>
                            <input
                                //value={correo}
                                {...register("correo", {
                                    required: "Este campo es obligatorio",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Dirección de correo electrónico no válida"
                                    }
                                })}
                                // onChange={(e) => setCorreo(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder='Ingrese un correo, Ej: receptor@gmail.com'
                            />
                            {errors.correo && <p className="Msjvalidaciones">{errors.correo.message}</p>}
                        </div>
                        <div className="form-group">
                            <label className="form-label">Dirección</label>
                            <input
                                //value={direccion}
                                {...register("direccion", { required: true, minLength: 5, maxLength: 100 })}
                                // onChange={(e) => setDireccion(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder='Ingrese una dirección, Ej: Compañia de jessus 1390'
                            />
                            {errors.direccion && <p className='Msjvalidaciones'>Ingrese una dirección (entre 5 y 100 caracteres).</p>}
                        </div>
                        <div className="form-group">
                            <label className="form-label">Oficina</label>
                            <input
                                //value={oficina}
                                {...register("oficina", { required: true, minLength: 1, maxLength: 50 })}
                                // onChange={(e) => setOficina(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder='Ingrese N° oficina, Ej: 508'
                            />
                            {errors.oficina && <p className='Msjvalidaciones'>Ingrese N° oficina.</p>}
                        </div>
                        <div className="form-group">
                            <label className="form-label">Teléfono</label>
                            <input
                                // value={telefono}
                                {...register("telefono", { required: true, minLength: 9})}
                                // onChange={(e) => setTelefono(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder='Ingrese un telefono, Ej: 914257452 o 221478577'
                            />
                            {errors.telefono && <p className='Msjvalidaciones'>Ingrese N° de telefono.</p>}
                        </div>



                        <div className="form-group">
                            <label className="form-label">Sistema</label>
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        value="LIBRA"
                                        checked={sistema === 'LIBRA'}
                                        onChange={handleSistemaChange}
                                    />
                                    <img className='imgSistema' src={libra} alt="LIBRA" />
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="OTRO"
                                        checked={sistema === 'OTRO'}
                                        onChange={handleSistemaChange}
                                    />
                                    <img className='imgSistema' src={otro} alt="OTRO" />
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="SIN SISTEMA"
                                        checked={sistema === 'SIN SISTEMA'}
                                        onChange={handleSistemaChange}
                                    />
                                    <img className='imgSistema' src={sin_sistema} alt="SIN SISTEMA" />
                                </label>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-12 mb-3">
                            <div className="form-group">
                                <label className="form-label">Jurisdicción (Región)</label>
                                <select value={regionId || ''} onChange={handleRegionChange} className="form-control">
                                    <option value="" disabled>Seleccionar Región</option>
                                    {regiones.map((region) => (
                                        <option key={region.id} value={region.id}>{region.nombre}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Comunas</label>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="selectAllComunas"
                                        checked={selectAllComunas}
                                        onChange={handleComunaChange}
                                    />
                                    <label className="form-check-label">Seleccionar todas las comunas</label>
                                </div>
                                {regionComunas.map((comuna) => (
                                    <div key={comuna.id} className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            value={comuna.id}
                                            checked={comunasSeleccionadas.includes(comuna.id)}
                                            onChange={handleComunaChange}
                                        />
                                        <label className="form-check-label">{comuna.nombre}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="form-group">
                                <label htmlFor="receptor_img">Imagen</label>
                                <input type="file" name="receptor_img" id="receptor_img" onChange={handleImagenChange} accept=".jpg, .jpeg, .png" />
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-success btnGuardar">
                                    <FaSave /> Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default CompEditReceptor;