import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { useAuth } from '../AuthContext';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { API_ROUTES } from '../config';

const Login = () => {
  const { setIsLoggedIn, AlertaExitosa, AlertaErrorLogin } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [logoutTimer, setLogoutTimer] = useState(null);

  useEffect(() => {
    const storedAuth = localStorage.getItem('isLoggedIn');
    if (storedAuth === 'true') {
      setIsLoggedIn(true);
      navigate('/adminreceptores');
    }
  }, [setIsLoggedIn, navigate]);

  const startLogoutTimer = () => {
    const timer = setTimeout(() => {
      handleLogout(); // Cerrar sesión automáticamente cuando el temporizador expire
    }, 30 * 60 * 1000); // 30 minutos en milisegundos
    setLogoutTimer(timer);
  };

  const resetLogoutTimer = () => {
    clearTimeout(logoutTimer); // Reiniciar el temporizador
    startLogoutTimer(); // Comenzar el temporizador nuevamente
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(API_ROUTES.login, {
        username,
        password,
      });

      setIsLoggedIn(true);
      localStorage.setItem('isLoggedIn', 'true');
      navigate('/adminreceptores');
      AlertaExitosa();
      startLogoutTimer(); // Iniciar el temporizador después de iniciar sesión
    } catch (error) {
      AlertaErrorLogin();
      setError(error.response.data.message);
    }
  };

  const handleLogout = () => {
    // Realiza la lógica de cierre de sesión aquí, como limpiar el localStorage, etc.
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
    clearTimeout(logoutTimer); // Limpiar el temporizador
    navigate('/login'); // Redirigir a la página de inicio de sesión
  };

  return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <div className="border border-3 border-secondary"></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 text-uppercase ">ACCESO</h2>
                  <p className=" mb-5">¡Por favor, introduce tu usuario y contraseña!</p>
                  {error && <p>{error}</p>}
                  <div className="mb-3">
                    <Form onSubmit={handleLogin}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">Usuario</Form.Label>
                        <Form.Control type="text" placeholder="Correo" value={username} onChange={(e) => setUsername(e.target.value)} />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control type="password" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} />
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="secondary" type="submit" onClick={resetLogoutTimer}>Login</Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
