import React, { useEffect } from 'react';
import Nosotros from '../components/Nosotros';
import Banner from '../components/Banner';
import GaleriaNoticias from '../components/GaleriaNoticias';
import Contacto from '../components/Contacto';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    // Verificar si hay un hash en la URL
    const hash = location.hash;
    if (hash) {
      // Esperar un breve momento para asegurarse de que los elementos se hayan renderizado
      setTimeout(() => {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    }
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>Asociación Nacional de Receptores Judiciales de Chile</title>
      </Helmet>
      
      <>
        <Banner></Banner>
        <Nosotros></Nosotros>
        <GaleriaNoticias></GaleriaNoticias>
        <Contacto></Contacto>
      </>
    </div>
  );
};

export default Home;
