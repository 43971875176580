import './css/style.css';
import React from 'react';
import { Routes, Route, Navigate, BrowserRouter, HashRouter } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Barra from './components/Barra';
import BarraAdmin from './components/BarraAdmin';
import Footer from './components/Footer';
import CompShowReceptores from './components/receptor/ShowReceptores';
import CompCreateReceptor from './components/receptor/CreateReceptor';
import CompEditReceptor from './components/receptor/EditReceptor';
import Login from './views/Login';
import Administrador from './views/Administrador';
import Home from './views/Home';
import Receptores from './views/Receptores';
import Noticias from './views/Noticias';
import Error404 from './components/Error404';
import CompShowNoticias from './components/Noticia/ShowNoticias';
import CompCreateNoticia from './components/Noticia/CreateNoticia';
import CompEditNoticia from './components/Noticia/EditNoticia';
import CompShowCarrusel from './components/Carrusel/ShowCarrusel';
import CompEditCarrusel from './components/Carrusel/EditCarrusel';
import CompShowNosotros from './components/Nosotros/ShowNosotros';
import CompEditNosotros from './components/Nosotros/EditNosotros';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';



function App() {

  const { isLoggedIn, setIsLoggedIn } = useAuth(); // Obtener el estado isLoggedIn desde el contexto
  
  
  useEffect(() => {
    const storedAuth = localStorage.getItem('isLoggedIn');
    setIsLoggedIn(storedAuth === 'true' ? true : false);
  }, [setIsLoggedIn]);

  return (
    <div className="App">
    
      <BrowserRouter>

      <Helmet>
        <title>Asociación Nacional de Receptores Judiciales de Chile.</title>
        <meta name="description" content="Asociación Nacional de Receptores Judiciales de Chile" />
        <meta name="robots" content="index, follow" />
      </Helmet>
     
        <Barra />
        {isLoggedIn && <BarraAdmin />} {/* Muestra BarraAdmin solo si isLoggedIn es true */}
        <div className="content">
        <Routes>
          {/* Rutas protegidas */}
          {isLoggedIn ? (
            <>
              <Route path="/administrador" element={<Administrador />} />
              <Route path="/adminreceptores" element={<CompShowReceptores />} />
              <Route path="/adminnoticias" element={<CompShowNoticias />} />
              <Route path="/admincarrusel" element={<CompShowCarrusel />} />
              <Route path="/adminnosotros" element={<CompShowNosotros />} />
              <Route path="/createreceptor" element={<CompCreateReceptor />} />
              <Route path="/createnoticia" element={<CompCreateNoticia />} />
              <Route path="/edit/:receptorId" element={<CompEditReceptor />} />
              <Route path="/editnoticia/:noticiaId" element={<CompEditNoticia />} />
              <Route path="/editcarrusel/:carruselId" element={<CompEditCarrusel />} />
              <Route path="/editnosotros/:nosotrosId" element={<CompEditNosotros />} />
              <Route path="/logout" element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="/receptores" element={<Receptores />} />
              <Route path="/noticias" element={<Noticias />} />
              <Route path="/login" element={<Login />} />
            </>

          ): (
            <Route path="*" element={<Error404 />} />
          )}
          {/* Rutas públicas */}
            <>
              <Route path="/" element={<Home />} />
              <Route path="/receptores" element={<Receptores />} />
              <Route path="/noticias" element={<Noticias />} />
              <Route path="/login" element={<Login />} />
            </>
          
        </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>

  );
}

export default App;