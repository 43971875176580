import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Importamos useNavigate desde react-router-dom
import { API_ROUTES } from '../../config';
import { FaPlus, FaTrash, FaEdit } from "react-icons/fa";
import { useAuth } from '../../AuthContext';



const CompShowNoticias = () => {
  const [noticias, setNoticias] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedNoticia, setSelectedNoticia] = useState(null);
  const { AlertaExitosaEliminar } = useAuth();

  useEffect(() => {
    getNoticias();
  }, []);

  const getNoticias = async () => {
    try {
      const res = await axios.get(API_ROUTES.noticias);
      setNoticias(res.data);
    } catch (error) {
      console.error('Error al obtener noticias:', error);
    }
  };

  const eliminarNoticia = async (noticiaId) => {
    try {
      AlertaExitosaEliminar();
      await axios.delete(`${API_ROUTES.noticias}${noticiaId}`);
      // Actualiza la lista de noticias después de la eliminación
      getNoticias();
    } catch (error) {
      console.error('Error al eliminar la noticia:', error);
    }
  };

  const openModal = (noticia) => {
    setSelectedNoticia(noticia);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedNoticia(null);
  };

  return (
    <div>
      <div className='container mb-3 seccionGestionarNoticias'>
        <div className='text-center'><h4>Gestionar Noticias</h4></div>
        <Link to={`/createnoticia`} className='btn btn-primary btnCrear'>
          <FaPlus /> Crear
        </Link>
        <div className='row'>
          <div className='col'>
            <Table striped bordered hover responsive="sm">
              <thead className='table-secondary'>
                <tr>
                  <th>Título</th>
                  <th>Detalle</th>
                  <th>Urgencia</th>
                  <th>Imagen</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {noticias.map((noticia) => (
                  <tr key={noticia.id}>
                    <td>{noticia.titulo}</td>
                    <td style={{ maxWidth: '200px', wordWrap: 'break-word', whiteSpace: 'pre-line', textAlign: 'justify' }}>
                      {noticia.detalle.length > 50 ? (
                        <>
                          <div dangerouslySetInnerHTML={{ __html: noticia.detalle.substring(0, 50) }} />
                          <Button
                            variant='link'
                            onClick={() => openModal(noticia)}
                          >
                            Ver más
                          </Button>
                        </>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: noticia.detalle }} />
                      )}
                    </td>
                    <td>{noticia.estado}</td>
                    <td>
                      {noticia.noticia_img && (
                        <img
                          src={`${API_ROUTES.noticiasUploads}${noticia.noticia_img}`}
                          alt={`Imagen de ${noticia.noticia_img}`}
                          style={{ maxWidth: '150px' }}
                        />
                      )}
                    </td>
                    <td className='estilo-accion'>
                      <div>
                        <Link
                          to={`/editnoticia/${noticia.id}`}
                          className='btn btn-info'
                          style={{ width: '103px' }}
                        ><FaEdit />
                          Editar
                        </Link>
                      </div>
                      <div>
                        <Button
                          className='btn btn-danger'
                          onClick={() => eliminarNoticia(noticia.id)}
                          style={{ width: '103px' }}
                        ><FaTrash />
                          Eliminar
                        </Button>
                      </div>

                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <Modal size="lg" show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Modal.Title style={{ textAlign: 'center' }}>{selectedNoticia?.titulo}</Modal.Title>
    </div>
        </Modal.Header>
        <Modal.Body style={{ whiteSpace: 'pre-line', textAlign: 'justify' }}>
          <div style={{ textAlign: 'center' }}>
          <img
                          src={`${API_ROUTES.noticiasUploads}${selectedNoticia?.noticia_img}`}
                          alt={`Imagen de ${selectedNoticia?.noticia_img}`}
                          style={{ width: "500px", display: 'inline-block0', borderRadius:'10px', margin:'50px' }}
                        />
          </div>
          <p dangerouslySetInnerHTML={{ __html: selectedNoticia?.detalle.replace(/<br\s*\/?>/gi, '\n') }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompShowNoticias;