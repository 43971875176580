import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { API_ROUTES } from "../../config";
import { FaSave } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../AuthContext';



const CompEditNosotros = () => {
    const { register, handleSubmit, formState: { errors}, setValue, watch } = useForm();
    const navigate = useNavigate();
    const { nosotrosId } = useParams();
    //const [titulo, setTitulo] = useState('');
    //const [detalle, setDetalle] = useState('');
    const titulo = watch('titulo');
    const detalle = watch('detalle');
    const [nosotrosImg, setNosotrosImg] = useState(null); 
    const [nosotrosImgActual, setNosotrosImgActual] = useState('');
    const [contadorCaracteres, setContadorCaracteres] = useState(0);
    const { AlertaExitosaEditar, AlertaError } = useAuth();


    useEffect(() => {

        axios.get(`${API_ROUTES.nosotros}${nosotrosId}`)
            .then((response) => {
                const { data } = response;
                setValue('titulo', data.titulo);
                // Procesar el detalle para convertir saltos de línea HTML en saltos de línea regulares
                const detalleConSaltosHTML = data.detalle.replace(/<br\s*\/?>/gi, '\n');
                setValue('detalle', detalleConSaltosHTML);
                setNosotrosImgActual(`${API_ROUTES.nosotrosUploads}${data.img}`);
                setContadorCaracteres(detalleConSaltosHTML.length);
            })
            .catch((error) => {
                console.error('Error al obtener detalles de seccion nosotros:', error);
            });
    }, [nosotrosId]);


    useEffect(() => {
        if (detalle) {
          
          const detalleSinSaltosHTML = detalle.replace(/<br\s*\/?>/gi, '\n');
    
          const caracteresContados = detalleSinSaltosHTML.length + (detalleSinSaltosHTML.match(/\n/g) || []).length;
          setContadorCaracteres(caracteresContados);
        }
      }, [detalle]);


    const enviar = async (e) => {
       // e.preventDefault();
       AlertaExitosaEditar();

        const formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('detalle', detalle);

        // Agregar la imagen solo si hay una nueva imagen seleccionada
        if (nosotrosImg) {
            formData.append('img', nosotrosImg);
        }

        try {
            await axios.put(`${API_ROUTES.nosotros}${nosotrosId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            //console.log('Seccion nosotros editada de forma correcta');
            navigate('/adminnosotros');
        } catch (error) {
            AlertaError();

            console.error('Error al editar seccion nosotros:', error);

        }
    };

    const seleccionarImagen = (event) => {
        setNosotrosImg(event.target.files[0]);
    };



    return (

        <div className="container" style={{ marginTop: '80px', marginBottom: '80px' }}>
            <h3>Editar Nosotros</h3>
            <form onSubmit={handleSubmit(enviar)} encType="multipart/form-data">
                <div className="mb-3">
                    <label className="form-label">Título</label>
                    <input 
                    placeholder='Ingrese un título'
                   // value={titulo} 
                   // onChange={(e) => setTitulo(e.target.value)} 
                    type="text" 
                    className="form-control" 
                    maxLength="100"
                     {...register("titulo", { required: true, minLength: 4, maxLength: 100 })}
                     />
                    {errors.titulo && <p className='Msjvalidaciones'>Ingrese un título válido (entre 4 y 100 caracteres).</p>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Detalle</label>
                    <textarea
                        placeholder='Ingrese el detalle'
                        //value={detalle}
                        //onChange={(e) => setDetalle(e.target.value)}
                        type="text" 
                        className="form-control"
                        style={{height:'300px'}}
                        maxLength="2500"
                        {...register("detalle", { required: true, minLength: 4})}
                    />
                    <div id='contadorCaracteres'>{contadorCaracteres}/2500 Max caracteres</div>
                    {errors.detalle && <p className='Msjvalidaciones'>Ingrese un detalle válido (entre 4 y 2500 caracteres).</p>}
                    
           
                </div>
                <div className="mb-3">
                    <label className="form-label">Imagen Actual</label>
                    {nosotrosImgActual && (
                        <div className="mt-2">
                            <img src={nosotrosImgActual} alt="Imagen actual del sec nosotros" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </div>
                    )}
                </div>
                <div className="mb-3">
                    <label className="form-label">Nueva Imagen</label>
                    <input type="file" name="img" id="img" onChange={seleccionarImagen} />
                </div>
                <div className="text-center">
                <button type="submit" className="btn btn-success btnGuardar">
                <FaSave /> Guardar
                </button>
                </div>
            </form>
        </div>

        
    )

};

export default CompEditNosotros;