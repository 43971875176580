import axios from 'axios';
import { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Importamos useNavigate desde react-router-dom
import { API_ROUTES } from '../../config';
import { FaPlus, FaTrash, FaEdit } from "react-icons/fa";
import { useAuth } from '../../AuthContext';
import sin_avatar from '../../img/sin-avatar.png';




const CompShowReceptores = () => {
    const { AlertaExitosaEliminar } = useAuth();
    const [receptores, setReceptores] = useState([]);



    useEffect(() => {
        getReceptores()
    }, []);



    //proceso para mostrar todos los receptores
    const getReceptores = async () => {
        const res = await axios.get(API_ROUTES.receptoresConComunas)
        setReceptores(res.data)

    }

    const eliminarReceptor = async (receptorId) => {
        try {
            AlertaExitosaEliminar();
            await axios.delete(`${API_ROUTES.receptores}${receptorId}`);
            getReceptores(); // Vuelve a cargar la lista después de eliminar
        } catch (error) {
            console.error('Error al eliminar el receptor:', error);
        }
    };



    return (

        <div>

            <div className='container mb-3 seccionGestionarReceptores'>
                <div className='text-center'><h4>Gestionar Receptores</h4></div>
                <Link to={`/createreceptor`} className='btn btn-primary btnCrear' ><FaPlus /> Crear</Link>
                <div className='row'>
                    <div className='col'>
                    <div className='table-responsive'>
                        <Table striped bordered hover responsive="sm">

                            <thead className='table-secondary'>
                                <tr>
                                    <th>Nombre Receptor</th>
                                    <th>Correo</th>
                                    <th>Dirección</th>
                                    <th>Oficina</th>
                                    <th>Teléfono</th>
                                    <th>Jurisdicción</th>
                                    <th>Comunas</th>
                                    <th>Sistema</th>
                                    <th>Imagen</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>


                            <tbody>
                                {
                                    receptores.map((receptor) => (
                                        <tr key={receptor.id}>
                                            <td>{receptor.nombre}</td>
                                            <td>{receptor.correo}</td>
                                            <td>{receptor.direccion}</td>
                                            <td>{receptor.oficina}</td>
                                            <td>{receptor.telefono}</td>
                                            <td>{receptor.regione ? receptor.regione.nombre : 'N/A'}</td>
                                            <td>{receptor.comunas ? receptor.comunas.map(comuna => comuna.nombre).join(', ') : 'N/A'}</td>
                                            <td>{receptor.sistema}</td>
                                            <td>
                                                {/* Mostrar imagen si hay una ruta de imagen válida */}
                                                {receptor.receptor_img ? (
                                                    <img
                                                        src={`${API_ROUTES.receptoresUploads}${receptor.receptor_img}`}
                                                        alt={`Imagen de ${receptor.nombre}`}
                                                        style={{ maxWidth: '70px' }} // Ajustar el tamaño de la imagen según tus necesidades
                                                    />
                                                ) : (
                                                    <img
                                                        src={sin_avatar}
                                                        alt="Imagen por defecto"
                                                        style={{ maxWidth: '70px' }} // Ajustar el tamaño de la imagen por defecto según tus necesidades
                                                    />
                                                )}
                                            </td>
                                            <td className='estilo-accion'>
                                                <Link to={`/edit/${receptor.id}`} className='btn btn-info' style={{ width: '103px' }}><FaEdit /> Editar</Link>
                                                <Button className='btn btn-danger' onClick={() => eliminarReceptor(receptor.id)} style={{ width: '103px' }}><FaTrash /> Eliminar</Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>

                        </Table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}

export default CompShowReceptores;