import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_ROUTES } from '../../config';
import { FaSave } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../AuthContext';


//const URI = 'http://localhost:8000/receptores/nuevanoticia';

const CompCreateNoticia = () => {
  const navigate = useNavigate();
  const { register, formState: { errors }, handleSubmit } = useForm();
  const { AlertaExitosaCrear, AlertaError } = useAuth();

  const [titulo, setTitulo] = useState('');
  const [detalle, setDetalle] = useState('');
  const [noticia_img, setNoticia_img] = useState(null);
  const [estado, setEstado] = useState('importante');
  const [contadorCaracteres, setContadorCaracteres] = useState(0);




  const enviar = async (e) => {
    //e.preventDefault();
    AlertaExitosaCrear();

    /*console.log('Formulario enviado:', {
      titulo,
      detalle,
      noticia_img,
      estado,
    });*/

    

    const formData = new FormData();
    formData.append('titulo', titulo);
    formData.append('detalle', detalle);
    formData.append('noticia_img', noticia_img);
    formData.append('estado', estado);

    try {
      //console.log('Enviando solicitud POST...');
      const response = await axios.post(API_ROUTES.noticiasNuevas, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

     // console.log('Noticia creada:', response.data);
      navigate('/adminnoticias');
    } catch (error) {
      AlertaError();
      console.error('Error al crear noticia:', error);
    }
  };

  const seleccionarEstado = (event) => {
    setEstado(event.target.value);
  };

  const seleccionarImagen = (event) => {
    const selectedImage = event.target.files[0];
    //console.log('Imagen seleccionada:', selectedImage);
    setNoticia_img(selectedImage);
  };





  return (


    <div className="container" style={{ marginTop: '80px', marginBottom: '80px' }}>
      <h3>Crear Noticia</h3>
      <form onSubmit={handleSubmit(enviar)} encType="multipart/form-data">
        <div className="mb-3">
          <label className="form-label">Título</label>
          <input
          
            placeholder='Ingrese un título'
            type="text"
            className="form-control"
            maxLength="100"
            {...register("titulo", { required: true, minLength: 4, maxLength: 100 })}
            onChange={(e) => setTitulo(e.target.value)}
          />
          {errors.titulo && <p className='Msjvalidaciones'>Ingrese un título válido (entre 4 y 100 caracteres).</p>}
        </div>
        <div className="mb-3">
          <label className="form-label">Detalle</label>
          <textarea
            placeholder='Ingrese el detalle de la noticia'
            value={detalle}
            {...register("detalle", { required: true, minLength: 20, maxLength: 5000 })}
            onChange={(e) => {
              setDetalle(e.target.value);
              setContadorCaracteres(e.target.value.length);
            }}
            className="form-control"
            maxLength="5000"
            rows="4"
          />
          {errors.detalle && <p className='Msjvalidaciones'>Ingrese el detalle (entre 20 y 5000 caracteres).</p>}
          <div id='contadorCaracteres'>{contadorCaracteres}/5000 Max caracteres</div>
        </div>
        <div className="mb-3">
          <label className="form-label">Estado</label>
          <div>
            <label class="form-check-label" htmlFor="importante">
              <input
                style={{margin:'5px'}}
                class="form-check-input"
                type="radio"
                id="importante"
                value="importante"
                checked={estado === 'importante'}
                onChange={seleccionarEstado}
                className='radioEstado'
              />
              IMPORTANTE
            </label>

            <label class="form-check-label" htmlFor="comunicado">
              <input
                style={{margin:'5px'}}
                class="form-check-input"
                type="radio"
                id="comunicado"
                value="comunicado"
                checked={estado === 'comunicado'}
                onChange={seleccionarEstado}
                className='radioEstado'
              />
              COMUNICADO
            </label>

            <label class="form-check-label" htmlFor="informacion">
              <input
               style={{margin:'5px'}}
                class="form-check-input"
                type="radio"
                id="informacion"
                value="información"
                checked={estado === 'información'}
                onChange={seleccionarEstado}
                className='radioEstado'
              />
              INFORMACIÓN
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="noticia_img">Imagen:</label>
          <input
            type="file"
            name="noticia_img"
            id="noticia_img"
            onChange={seleccionarImagen}
            required
            accept=".jpg, .jpeg, .png" 
          />
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-success btnGuardar">
            <FaSave /> Crear
          </button>
        </div>
      </form>
    </div>
  );
};



export default CompCreateNoticia;